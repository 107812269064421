import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// https://www.gatsbyjs.com/docs/add-seo-component/

const Head = () => (
  <Helmet
    title={'Oussama Abdallah'}
    defaultTitle={'Oussama Abdallah'}
    titleTemplate={`Oussama Abdallah`}>
    <html lang="en" />
    <meta
      name="description"
      content={
        'Oussama is a fullstack developer who specializes in building (and occasionally designing) exceptional digital experiences.'
      }
    />
    <meta property="og:title" content={'Oussama Abdallah'} />
    <meta
      property="og:description"
      content={
        'Oussama is a fullstack developer who specializes in building (and occasionally designing) exceptional digital experiences.'
      }
    />
    <meta property="og:url" content={'oussamaabdallah.com'} />
    <meta property="og:type" content="website" />

    <meta name="twitter:card" content="summary_large_image" />
  </Helmet>
);

export default Head;

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

Head.defaultProps = {
  title: null,
  description: null,
  image: null,
};
